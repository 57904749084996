<template>
  <section id="login_container">
    <div class="contents_wrap">
      <router-view/>
    </div>
    <div class="login_copy"> 시스템 사용 문의 : 1533-4810(ARS 9번, 1번)<br/>
      <p>ⓒ 2020 etners Co.,Ltd</p>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'CommonLoginLayout',
    methods: {},
  }
</script>
